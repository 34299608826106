import React, { useState, useRef, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';

function useIntersectionObserver(
  elementRef,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false },
) {
  const [entry, setEntry] = useState();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]) => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}

const SlideUp = ({ children, freeze = false }) => {
  const triggerHeaderRef = useRef();
  const headerRef = useIntersectionObserver(triggerHeaderRef, {
    freezeOnceVisible: freeze,
  });
  const headerStyle = useSpring({
    config: { duration: 500 },
    from: { bottom: '-50px', marginTop: '20%' },
    to: {
      bottom: headerRef?.isIntersecting ? '0px' : '-50px',
      marginTop: headerRef?.isIntersecting ? '0px' : '20%',
    },
  });

  return (
    <>
      <div ref={triggerHeaderRef} />
      <animated.h3 style={headerStyle}>{children}</animated.h3>
    </>
  );
};

SlideUp.propTypes = {
  children: PropTypes.node,
  freeze: PropTypes.bool,
};

export default SlideUp;
