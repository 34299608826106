import React from 'react';
import Pages from '../../components/Pages';
import Team from '../../components/pages/team';

const TeamPage = () => {
  return (
    <Pages>
      <Team />
    </Pages>
  );
};

export default TeamPage;
