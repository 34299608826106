import React from 'react';
import IMG_BANNER from 'images/jumbotron-bg.png';
import PropTypes from 'prop-types';
import './styles.css';
import SlideUp from 'components/animation/SlideUp';
import FadeInLeft from 'components/animation/FadeInLeft';
import FadeInRight from 'components/animation/FadeInRight';
import { graphql, useStaticQuery, Link } from 'gatsby';
import CustomContainer from '../../CustomContainer';
import { useMediaQuery, useWindowWidth } from '../../hooks/useResponsive';

const CardCustom = ({ item }) => {
  return (
    <Link to={`/team/${item?.slug}`}>
      <div className="px-14 sm:px-0 cursor-pointer">
        <div className="h-[300px] sm:h-[400px] lg:h-[280px] xl:h-[400px] overflow-hidden">
          <div
            className="card-img"
            style={{
              backgroundImage: `url(${item?.src})`,
            }}
          />
        </div>
        <p className="text-[22px] font-extralight mt-3">{item?.name}</p>
        <p className="text-sm opacity-50 leading-tight font-extralight">
          {item?.title}
        </p>
      </div>
    </Link>
  );
};

const renderCardList = (data) => {
  return data.map((item) => {
    return <CardCustom item={item?.node} key={item?.index} />;
  });
};

const Team = () => {
  const DATAS = useStaticQuery(graphql`
    query {
      partners: allTeamJson(filter: { headline: { eq: "Partner" } }) {
        edges {
          node {
            headline
            description
            index
            location
            name
            title
            src
            slug
          }
        }
      }
      associates: allTeamJson(filter: { headline: { eq: "Associate" } }) {
        edges {
          node {
            headline
            description
            index
            location
            name
            title
            src
            slug
          }
        }
      }
    }
  `);

  const PARTNERS = DATAS.partners.edges;
  const ASSOCIATES = DATAS.associates.edges;

  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();

  return (
    <div className="bg-brand-black text-brand-white min-h-screen font-light pt-[70px] sm:pt-0">
      {/* jumbotron */}
      <div
        className="h-full sm:h-screen flex justify-center items-center px-0 py-10 sm:px-20 sm:py-0"
        style={{
          backgroundImage: `url(${IMG_BANNER})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <SlideUp>
          <CustomContainer className="w-full">
            <p
              className="text-base sm:text-md lg:text-lg text-center"
              style={{
                textShadow: '0px 0px 12px rgba(251, 252, 228, 0.25)',
              }}
            >
              We are not investors,{windowWidth <= mediaQuery.lg && <br />} we
              are not stock-pickers. <br />
              We are <b className="font-semibold">
                entrepreneurs ourselves,
              </b>{' '}
              and we work with entrepreneurs in an{' '}
              <b className="font-semibold">entrepreneurial way.</b>
            </p>
          </CustomContainer>
        </SlideUp>
      </div>
      {/* partners section */}
      <CustomContainer>
        <div className="py-14 sm:py-20">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-10">
            <div className="flex w-full">
              <FadeInLeft>
                <p className="mb-3 sm:mb-0 text-md sm:text-lg">Partners</p>
              </FadeInLeft>
            </div>
            <div
              className="flex flex-wrap w-[100%] lg:w-[70%] text-right"
              style={{
                overflowX: 'hidden',
              }}
            >
              <FadeInRight>
                <p className="font-thin text-[18px]">
                  Experts dedicated to working collaboratively to increase the
                  value of your ideas and come up with game plans for your
                  business to thrive.
                </p>
              </FadeInRight>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 sm:gap-y-12 gap-x-8 sm:grid-cols-2 lg:grid-cols-4">
            {renderCardList(PARTNERS)}
          </div>
        </div>
        {/* Associate section */}
        <div className="py-14 sm:py-20">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-10">
            <div className="flex w-full">
              <FadeInLeft>
                <p className="mb-3 sm:mb-0 text-md sm:text-lg">Associate</p>
              </FadeInLeft>
            </div>
            <div
              className="flex flex-wrap w-[100%] lg:w-[70%] text-right"
              style={{
                overflowX: 'hidden',
              }}
            >
              <FadeInRight>
                <p className="font-thin text-[18px]">
                  Efficient front-liners who work closely with you and the
                  partners to make your bold ideas come true.
                </p>
              </FadeInRight>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 sm:gap-y-12 gap-x-8 sm:grid-cols-2 lg:grid-cols-4">
            {renderCardList(ASSOCIATES)}
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

CardCustom.propTypes = {
  item: PropTypes.shape(),
};

export default Team;
