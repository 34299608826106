import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';

function useIntersectionObserver(
  elementRef,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false },
) {
  const [entry, setEntry] = useState();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]) => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}

const FadeInRight = ({ children }) => {
  const triggerHeaderRef = useRef();
  const headerRef = useIntersectionObserver(triggerHeaderRef, {
    freezeOnceVisible: false,
  });
  const fadeInRight = useSpring({
    config: { duration: 500 },
    from: { opacity: '0', transform: 'translate(100px, 0)' },
    to: {
      opacity: headerRef?.isIntersecting ? '1' : '0',
      transform: headerRef?.isIntersecting
        ? 'translate(0px, 0px)'
        : 'translate(100px, 0px)',
    },
  });
  return (
    <>
      <animated.div style={fadeInRight}>{children}</animated.div>
      <div ref={triggerHeaderRef} />
    </>
  );
};

FadeInRight.propTypes = {
  children: PropTypes.node,
};

export default FadeInRight;
